$screen-xs: 375px;
$screen-sm: 600px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1440px;

@mixin for-size($screen-size) {
  @media (max-width: $screen-size + 'px') {
    @content;
  }
}

@mixin xs {
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin md {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $screen-lg) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $screen-xl) {
    @content;
  }
}
