@import './variables';

// This file container all design language (reusable & atomic) styles

@mixin text-big {
  font-family: $primary-font-family;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.36px;
  font-weight: 400;

  @include md {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 27px;
    letter-spacing: -0.27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
}

@mixin text-subheading {
  font-family: $primary-font-family;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.36px;
  font-weight: 400;

  @include md {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
}

@mixin text-subheading {
  font-family: $primary-font-family;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.36px;
  font-weight: 400;

  @include md {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
}

@mixin text-big-mobile {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 38px;
  /* or 106% */

  letter-spacing: -0.36px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin text-big-list {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: -0.36px;

  @include md {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 27px;
    letter-spacing: -0.27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
}

@mixin text-normal {
  font-family: $primary-font-family;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.2px;
  font-feature-settings: 'tnum' on, 'lnum' on;

  @include md {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: -0.27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
}

@mixin text-normal-italic {
  @include text-normal;
  font-style: italic;
}

@mixin text-italic {
  //this font does not work
  //font-style: $secondary-font-family;
  font-style: italic;
}

@mixin text-normal-small-caps {
  @include text-normal;
  font-variant: small-caps;
}

@mixin text-small-caps {
  font-variant: small-caps;
}

@mixin text-small {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 20px;
  letter-spacing: -0.19px;

  @include md {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
}

@mixin text-smallest {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.27px;
}

@mixin text-mobile {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.27px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin text-mobile-big {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 27px;
  letter-spacing: -0.27px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin text-mobile-small {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.27px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin text-checkbox {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
}

@mixin text-secondary-tab {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 28px;
  letter-spacing: -0.27px;

  @include md {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.27px;
  }
}

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.color-light-gray {
  color: $color-light-gray;
}

.color-lightest-gray {
  color: $color-lightest-gray;
}

.color-soft-green {
  color: $color-soft-green;
}

.color-soft-blue {
  color: $color-soft-blue;
}

.color-caution {
  color: $color-soft-yellow;
}

.color-attention {
  color: $color-soft-red;
}

.color-informative {
  color: $color-soft-cyan;
}

.background-primary {
  background: $color-primary;
}

.background-secondary {
  background: $color-secondary;
}

.background-white {
  background: $color-white;
}

.background-light-gray {
  background: $color-light-gray;
}

.background-lightest-gray {
  background: $color-lightest-gray;
}

.background-soft-green {
  background: $color-soft-green;
}

.background-caution {
  background: $color-soft-yellow;
}

.background-attention {
  background: $color-soft-red;
}

.background-informative {
  background: $color-soft-cyan;
}

.text-big {
  @include text-big();
}

.text-big-list {
  @include text-big-list();
}

.text-normal {
  @include text-normal();
}

.text-normal-italic {
  @include text-normal-italic();
}

.text-italic {
  @include text-italic();
}

.text-normal-small-caps {
  @include text-normal-small-caps();
}

.text-small-caps {
  @include text-small-caps();
}

.text-checkbox {
  @include text-checkbox();
}

.text-small {
  @include text-small;
}

.text-smallest {
  @include text-smallest;
}

.text-mobile {
  @include text-mobile();
}

.text-mobile-big {
  @include text-mobile-big();
}

.text-big-mobile {
  @include text-big-mobile();
}

.text-mobile-small {
  @include text-mobile-small();
}

.text-secondary-tab {
  @include text-secondary-tab();
}
