@import 'styles/globals';

.display {
  &-wrapper {
    position: relative;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include md {
      height: 443px;
    }
  }

  &-image {
    max-height: 500px;
    height: 100%;
    margin-bottom: 50px;
    width: auto;

    @include md {
      max-height: 300px;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
  }

  &-back-link {
    @include text-smallest;
    position: absolute;
    top: 16px;
    left: 15px;

    @include md {
      top: 20px;
      left: 10px;
    }
  }

  &-favorites {
    display: none;
    position: absolute;
    right: 30px;
    bottom: 20px;

    @include md {
      right: 0px;
      bottom: 0px;
    }
  }

  &-image {
    height: 500px;
    width: auto;
  }
}

.artwork-info {
  &-container {
    margin-top: 50px;
    margin-bottom: 100px;

    @include md {
      margin-top: 60px;
      margin-bottom: 50px;
    }

    > * {
      padding-bottom: 50px;

      @include md {
        padding-bottom: 40px;
      }
    }
  }

  &-bid {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;

    &-right {
      width: 450px;

      &-button {
        width: 100%;
        height: 60px;
        margin-bottom: 15px;
        color: rgba(255, 255, 255, 0.5);

        @include md {
          width: 100%;
          margin-bottom: 10px;
        }
      }

      &-countdown {
        @include text-small;
        width: 100%;
        height: 35px;

        @include md {
          width: 100%;
        }
      }

      @include md {
        width: 100%;
      }
    }

    &-left {
      &-price {
        display: flex;
        flex-direction: column;
      }
    }

    @include md {
      flex-direction: column;
      > :not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  &-about {
    width: 70%;
    display: flex;
    flex-direction: column;

    @include md {
      width: 100%;
    }
  }

  &-incoming-bids {
    width: 70%;
    display: flex;
    flex-direction: column;

    @include md {
      width: 100%;
    }
  }

  &-more {
    width: 70%;
    display: none;
    flex-direction: column;
    padding-bottom: 22px;

    > div {
      display: none;
      flex-direction: column;
      margin-bottom: 30px;

      @include md {
        margin-bottom: 40px;
      }
    }

    &-details {
      display: inline-block;
    }

    @include md {
      width: 100%;
      padding-bottom: 71px;
    }
  }

  &-explore {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    @include md {
      margin-bottom: 50px;
    }

    &-text {
      margin-bottom: 30px;

      @include md {
        margin-bottom: 10px;
      }
    }

    &-items {
      display: grid;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 15px;
      row-gap: 50px;
      grid-template-columns: repeat(1, 1fr);
      margin-left: auto;
      margin-right: auto;

      @include xl {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.view-more {
  margin: 100px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
