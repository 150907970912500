@import './variables';
@import './globals';
@import './layout';

@font-face {
  font-family: 'JJannon';
  font-style: normal;
  font-weight: 400;
  src: local('JJannon Book'), local('JJannon-Book'),
    url('../assets/fonts/JJannon-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'JJannon Italic';
  font-style: normal;
  font-weight: 400;
  src: local('JJannon Italic'), local('JJannon-Italic'),
    url('../assets/fonts/JJannon-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: local('Space Grotesk'), local('SpaceGrotesk'),
    url('../assets/fonts/SpaceGrotesk-Regular.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 27px;
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100%;
}

body {
  @include text-normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #fff;
  min-height: 100%;

  @include md {
    @include text-mobile;
  }
}

h1 {
  font-family: $primary-font-family;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.36px;
  font-weight: 400;

  @include md {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
}

h3 {
  @include text-normal;
  font-size: 27px;
  color: $color-secondary;
  margin-bottom: 16px;
}

h5 {
  font-weight: 400;
  margin-bottom: 16px;
}

// p {
//   margin-bottom: 8px;
// }

a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}
