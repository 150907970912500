@import 'styles/globals';

.secondaryTab {
  &-block {
    overflow-x: scroll;
  }

  &-container {
    @include text-secondary-tab;
    background: #ffffff;
    padding: 10px 27px 12px 40px;
    max-width: $screen-xl;
    width: max-content;

    > :not(:last-child) {
      margin-right: 50px;
    }

    @include md {
      padding: 4px 27px 6px 20px;
      > :not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}

.active-tab-selection {
  color: $color-primary;
}

.dot {
  &:before {
    content: '• ';
  }
}
