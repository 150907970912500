@import '@styles/globals';

.hero {
  &-container {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 48px 0;
    transition: 0.3s;

    @include md {
      padding: 0px;
      padding-top: 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  &-content {
    @include text-subheading;
    width: 100%;
    max-width: 1054px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    > p:nth-child(2) {
      margin-top: 0.5em;
      font-size: 27px;
    }
  }

  &-details {
    @include text-normal;
    margin-top: 130px;
    max-width: 1054px;
  }
}
