@import '@styles/variables';
@import '@styles/globals';

.logoText {
  letter-spacing: -0.675px;
  margin-left: 4px;
}

.navbar {
  &-container {
    background-color: $color-white;
    padding: 15.4px 19px 10.6px 10px;
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $screen-xl;
    margin-left: auto;
    margin-right: auto;

    &-open {
      position: fixed;
      top: 0px;
      left: 0px;
      padding: 9px 15px 0px 10px;
      background-color: #fff;
      z-index: 5;
    }
  }

  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-items-container {
    display: flex;
    flex-direction: row;

    > :not(:last-child) {
      margin-right: 4px;
    }
  }

  &-connection-data {
    margin-left: 70px;
  }

  &-list {
    @include text-big-mobile;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 40px;
    left: 0px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-top: 8px;
    z-index: 5;
    overflow: scroll;
  }

  &-links {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 70%;
  }

  &-contacts {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10% 0px 20px 0px;

    > * {
      margin-right: 10px;
    }
  }

  &-button {
    width: 100%;
    height: 60px;
    margin-bottom: 23px;
  }
}

.padded-navbar {
  padding: 24px 104px 24px 30px;
}
