@import 'styles/globals';

.lot-slider {
  &-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &-motion {
    width: 100%;
    height: 270px;

    &-div {
      position: absolute;
      width: 70%;
      height: 270px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-arrows {
    &-left {
      position: absolute;
      left: 22vw;
      top: 47.5%;
      cursor: pointer;
      user-select: none;
      @include text-big;
    }

    &-right {
      position: absolute;
      left: 76.5vw;
      cursor: pointer;
      user-select: none;
      top: 47.5%;
      @include text-big;
    }
  }

  &-description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 21px;

    @include text-smallest;

    > :not(:last-child) {
      margin-right: 2px;
    }
  }

  &-image {
    height: 270px;
    width: auto;
  }
}
