@import 'styles/globals';

.favorites {
  display: none;
  position: absolute;
  top: 425px;
  right: 0;

  @include md {
    top: 330px;
  }
}

.wrapper-component {
  &-container {
    position: relative;
    overflow: hidden;
    width: 450px;

    @include md {
      width: 355px;
    }
  }
}
