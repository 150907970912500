@import './layout';

$primary-font-family: 'JJannon', sans-serif;
$secondary-font-family: 'JJannon Italic', sans-serif;

$color-white: #ffffff;
$color-primary: #373737;
$color-secondary: #a7a7a7;
$color-light-gray: #f4f4f4;
$color-soft-green: #66ff66;
$color-soft-red: #ff6666;
$color-soft-yellow: #fff266;
$color-soft-cyan: #66ffff;
$color-soft-blue: #6666ff;
$color-lightest-gray: #fafafa;
