@import 'styles/variables';
@import 'styles/globals';

.first-child {
  margin-top: 20px;
}

.info-and-settings {
  &-gray-text {
    color: $color-secondary;
  }

  &-settings-block-flex {
    margin: 30px 0 100px 0px;
    > div {
      display: flex;
      margin: 15px 0;
      > p {
        margin-left: 15px;
      }
    }
  }

  &-settings-block-flex-mobile {
    margin: 30px 0 100px 0px;
    > div {
      display: flex;
      margin-bottom: 10px;
      > p {
        margin-left: 15px;
        margin-top: 6px;
      }
    }
  }

  &-button-block {
    padding-top: 50px;
  }

  &-button-block-mobile {
    width: 100%;
    margin-top: 50px;
  }

  &-button {
    text-transform: uppercase;
    width: 450px;
    font-size: 19px;
    &-edit-info-button {
      background-color: $color-light-gray;
      color: $color-primary;
    }
    &-disconnect-button {
      background-color: $color-primary;
      color: $color-white;
    }
    &-mobile {
      width: 100%;
    }
  }

  &-settings-block {
    margin: 30px 0 100px 0px;
    > div {
      margin-bottom: 10px;
      width: 450px;
    }
  }

  &-settings-block-mobile {
    margin: 30px 0 100px 0px;
    > div {
      margin-bottom: 10px;
    }
  }
}
