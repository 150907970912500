@import 'styles/globals';

.artworks {
  display: flex;
}

.text-box {
  margin-top: 30px;
  width: 71%;

  @include md {
    width: 100%;
  }
}

.container {
  margin-top: 10px;
  margin-bottom: 20px;
}

.board {
  margin-top: 40px;
  margin-bottom: 150px;

  &-text {
    margin-top: 90px;
    margin-bottom: 22px;

    > * {
      margin-right: 60px;
    }
  }
}

.back-link {
  margin: 15px 0 0 15px;
}

.items-display {
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 50px;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 130px;
  margin-left: auto;
  margin-right: auto;

  @include xl {
    //justify-content: center;
    grid-template-columns: repeat(3, 1fr);
  }
}

.artworks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.view-more {
  margin: 100px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
