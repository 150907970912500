@import 'styles/globals';

.notif-mgr {
  &-container {
    position: relative;
    max-width: $screen-xl;
    width: 100%;
    height: 0px;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    z-index: 9999;
  }

  &-popup {
    position: fixed;
    right: 15px;
    top: 60px;

    @include md {
      top: 15px;
      right: 10px;
      margin-left: 10px;
    }

    > :not(:last-child) {
      margin-bottom: 15px;

      @include md {
        margin-bottom: 10px;
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
    }
  }
}
