@import 'styles/globals';

.countdown {
  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    overflow: hidden;
  }

  &-box {
    background-color: $color-light-gray;
    padding: 8px 0px 7px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-progress {
    max-width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  &-text {
    z-index: 4;
  }

  &-text-bid-history {
    z-index: 4;
    color: $color-secondary;
  }
}
