@import 'styles/globals';

.favorites {
  &-container {
    @include text-small;
    border-radius: 9px;
    padding: 8px 38px 7px 38px;
    cursor: pointer;
  }
}
