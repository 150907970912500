@import 'styles/globals';

.elementq {
  margin-top: 30px;
  margin-bottom: 30px;

  @include md {
    margin-top: 10px;
  }
}
.element {
  margin-top: 15px;
  margin-bottom: 30px;
}

.element-about {
  display: none;
  margin: 87px 0px;

  @include md {
    margin: 50px 0px;
  }
}

.about {
  &-form {
    display: none;
    flex-direction: column;
    max-width: 450px;
    width: 100%;

    &-checkbox {
      margin: 30px 0;
    }

    &-button {
      margin-top: 10px;
      margin-bottom: 312px;
      width: 100%;
      height: 60px;
      color: #fff;

      @include md {
        margin-bottom: 100px;
      }
    }
  }
}

.informative {
  margin-top: 15px;
  margin-bottom: 15px;

  &-second {
    display: none;
    margin-top: 25px;
    margin-bottom: 20px;
  }
}

.board {
  margin-top: 30px;
  margin-bottom: 30px;

  @include md {
    margin-top: 10px;
  }
}

.visitor-text {
  width: 71%;

  @include md {
    width: 100%;
  }
}

.schedule {
  margin-top: 100px;
  margin-bottom: 118px;

  @include md {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

.photo {
  max-width: 1031px;
  width: 100%;
  margin-top: 30px;
}

.days {
  margin-top: 30px;
  margin-bottom: 20px;
}

.display-container {
  display: flex;
}

.pic-description {
  margin-top: 20px;
  margin-bottom: 64px;

  @include md {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

.app-popup {
  width: 70%;

  @include md {
    width: 100%;
  }
}

.form-edits {
  width: 100%;

  > * {
    width: 100%;
  }

  > :not(:last-child) {
    margin-bottom: 25px;
  }
}
