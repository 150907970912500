@import 'styles/variables';

.button {
  &-container {
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-variant: small-caps;
    font-size: 19px;
    line-height: 28px;
    cursor: pointer;
    transition: 0.3s;
    border: none;
  }

  &-default {
    width: 222px;
    height: 60px;
    background-color: $color-light-gray;
    color: $color-primary;
  }
}
