@import 'styles/variables';
@import 'styles/globals';

.profile {
  &-container {
    height: 311px;
    margin-top: 20px;
  }
  &-gray-text {
    color: $color-secondary;
  }
}
