@import 'styles/globals';

.favorites {
  &-container {
    @include text-small;
    border-radius: 9px;
    padding: 8px 38px 7px 38px;
    cursor: pointer;
    user-select: none;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
