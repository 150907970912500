@import 'styles/variables';

.smallButton {
  padding: 16px 70px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &-default {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: medium;
    font-size: 20px;
    line-height: 28px;
    background-color: $color-light-gray;
    color: rgba($color-primary, 0.5);
  }

  &:hover,
  &:focus {
    background-color: $color-primary;
    color: rgba($color-white, 0.5);
  }
}
