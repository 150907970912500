@import 'styles/globals';

.help {
  &-display {
    margin-top: 35px;
  }
}

.element {
  margin-bottom: 35px;
}

.faq {
  &-form {
    display: none;
    flex-direction: column;
    max-width: 450px;
    width: 100%;

    > :not(:last-child) {
      margin-bottom: 15px;
    }
    &-button {
      margin-top: 15px;
      margin-bottom: 312px;
      width: 100%;
      height: 60px;
      color: #fff;

      @include md {
        margin-bottom: 100px;
      }
    }
  }
}

.question {
  display: none;
  margin-top: 15px;
  margin-bottom: 25px;
}

.about {
  margin-top: 30px;

  @include md {
    margin-top: 10px;
  }
}

.hero-text {
  padding: 100px 0px;
  margin-bottom: 70px;

  @include md {
    padding: 0;
  }
}
