@import 'styles/globals';

.countdown {
  &-container {
    @include text-small;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 35px;
  }
}

.wrapper-component {
  &-container {
    position: relative;
    overflow: hidden;
    width: 450px;

    @include md {
      width: 355px;
    }
  }
}
