@import 'styles/variables';
@import 'styles/globals';

.item-display {
  &-container {
    width: 450px;
    cursor: pointer;

    @include md {
      width: 354px;
    }
  }

  &-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 460px;
    border-radius: 9px;
    overflow: hidden;

    @include md {
      width: 354px;
      height: 360px;
    }
  }

  &-image {
    width: 450px;
    height: auto;

    @include md {
      width: 354px;
    }
  }

  &-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  &-bid-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
