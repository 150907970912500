@import 'styles/globals';
@import 'styles/variables';

.notification {
  &-block {
    min-height: 350px;
    margin-bottom: 30px;
  }
  &-block-date {
    margin-top: 20px;
  }
}
