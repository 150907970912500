@import 'styles/variables';

.largeButton {
  width: 460px;
  height: 60px;

  &-default {
    background-color: $color-light-gray;
    color: black;
  }
}
