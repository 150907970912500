@import 'styles/variables';

.switch {
  &-container {
    display: flex;
    flex-direction: row;
  }

  &-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 58px;
    height: 30px;
    background: hsla(0, 0%, 77%, 1);
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
  }

  &-label &-button {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 22px;
    height: 22px;
    border-radius: 45px;
    background: #fff;
  }

  &-checkbox:checked + &-label &-button {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }

  &-active {
    background: $color-primary;
  }
}
