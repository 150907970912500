@import 'styles/globals';

.artwork-slider {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 16px 10px 0px 10px;
    @include text-big;
  }

  &-motion-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-arrows {
    margin-top: 30px;
    cursor: pointer;
    user-select: none;
  }

  &-details {
    @include text-normal;
    width: 264px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 9px;
    padding: 13px 0px 13px 13px;
    margin: 15px auto 0px auto;
  }

  &-image-block {
    width: 355px;
    height: 360px;
    border-radius: 9px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &-image {
    width: 355px;
    height: auto;
  }
}
