@import 'styles/globals';

.partners-text {
  margin: 15px 0px 40px 0px;
}

.sponsor-logos {
  max-width: 853px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 77px;
  row-gap: 60px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 40px;
  padding: 16px 0;

  @include md {
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: column;
    row-gap: 30px;
    margin-bottom: 35px;
  }
}

.image-logo {
  max-height: 60px;
  width: auto;

  @include md {
    max-height: 50px;
    width: auto;
  }
}
