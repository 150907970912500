@import 'styles/globals';

.notification {
  &-text {
    color: red;
  }

  &-history-container {
    @include md {
      margin-bottom: 26px;
    }
  }
}
