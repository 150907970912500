@import 'styles/globals';

.view-more {
  margin: 100px 0 65px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;

  @include md {
    margin: 100px 0 142px 0;
  }
}

.back-to-top {
  margin-bottom: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}

.collection-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  column-gap: 15px;
  row-gap: 50px;
  //grid-template-columns: repeat(1, 1fr);
  justify-content: center;

  @include xl {
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
  }
}
