@import '@styles/variables';
@import '@styles/globals';

.logoText {
  letter-spacing: -0.675px;
  // margin-left: 4px;
}

.navbar {
  &-container {
    background-color: $color-white;
    padding: 1em;
    max-width: $screen-lg;
    margin: auto;
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    margin-left: auto;
    margin-right: auto;
  }

  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-items-container {
    display: flex;
    flex-direction: row;
    > :not(:last-child) {
      margin-right: 8px;
    }
  }

  &-connection-data {
    margin-left: 32px;
    display: flex;
    width: 100%;
  }
}

// Notification
.notification {
  padding-right: 10px;
}

.active-modal-selection {
  color: $color-secondary;
  &:before {
    content: '- ';
  }
}

.profile-modal {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 20;
  overflow: hidden;
  background-color: rgba($color: $color-light-gray, $alpha: 0.85);

  &-links-container {
    display: flex;
    flex-direction: column;
  }

  &-container {
    max-width: $screen-lg;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &-content {
    max-width: 222px;
    width: 100%;
    height: 222px;
    margin: 15px 15px 0px 0px;
    float: right;
    opacity: 1;
  }
}

.active {
  color: $color-primary;
  &:before {
    content: '⬤';
    font-size: 18px;
    color: $color-soft-green;
    padding-right: 4px;
  }
}

.active {
  color: $color-primary;
  &:before {
    content: '⬤';
    font-size: 18px;
    color: $color-soft-green;
    padding-right: 4px;
  }
}
