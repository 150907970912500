@import 'styles/variables';

.dropdown {
  &-container {
    width: 300px;
  }

  &-text {
    color: $color-secondary;
  }

  &-option {
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
  }

  &-display-box {
    position: relative;
    background: #ffffff;
    border-radius: 9px;
    width: 300px;
    padding: 13px;
    font-size: 27px;
    line-height: 28px;
    text-align: right;
    letter-spacing: -0.27px;
    margin-left: auto;
  }

  &-list {
    position: absolute;
    margin-top: -13px;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 5;
  }
}
