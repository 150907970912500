@import 'styles/globals';

.description {
  margin-bottom: 30px;
}

.dropdown {
  margin: 22px 0px 22px 0px;
  display: none;
  flex-direction: row;
  justify-content: flex-end;
}

.view-more {
  margin: 100px 0px 65px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;

  @include md {
    margin: 100px 0px 142px 0px;
  }
}

.back-to-top {
  margin-bottom: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}

.auction-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  column-gap: 15px;
  row-gap: 50px;
  justify-content: center;

  @include xl {
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
  }
}
