@import '@styles/variables';
@import '@styles/globals';

.logoText {
  position: relative;
  z-index: 10;
  letter-spacing: -0.675px;
  font-size: 34px;
  // z-index: 10;
}

.navbar {
  &-container {
    background-color: $color-white;
    padding: 24px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    @include md {
      padding: 15.4px 19px 10.6px 10px;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $screen-xl;
    margin-left: auto;
    margin-right: auto;
  }
}
