@import '@styles/globals';

.nviewer-modal {
  @include text-small;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 20;
  overflow: hidden;
  background-color: rgba($color: $color-light-gray, $alpha: 0.85);

  &-links-container {
    display: flex;
    flex-direction: column;
  }

  &-container {
    max-width: $screen-lg;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &-content {
    max-width: 460px;
    width: 100%;
    height: 222px;
    margin: 15px 15px 0px 0px;
    float: right;
    opacity: 1;

    &-container {
      max-height: 45vh;
      overflow-y: auto;
    }
  }
}
