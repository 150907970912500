@import 'styles/globals';

.bid {
  &-image {
    width: 200px;
    height: 220px;
  }
  &-image-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 270px;
    border-radius: 9px;
    background-color: $color-lightest-gray;
    @include md {
      width: 100%;
    }
  }

  &-text-block {
    width: 450px;
    margin-left: 15px;
    @include md {
      width: 100%;
      margin-top: 15px;
      margin-left: 0;
    }
  }

  &-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 20px;
    @include md {
      flex-direction: column;
      justify-content: center;
    }
  }

  &-status-lot {
    position: absolute;
    bottom: 20%;
    @include md {
      position: relative;
      margin-top: 20px;
    }
  }

  &-artwork-id-push {
    position: absolute;
    bottom: 0;
    @include md {
      position: relative;
      margin-top: 20px;
    }
  }

  &-preview-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 450px;
    height: 270px;
    overflow: hidden;
    @include md {
      width: 100%;
    }
  }

  &-image-container {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &-image-array {
    width: 100%;
    height: auto;
  }
}
