@import 'styles/variables';
@import 'styles/globals';

.checkbox {
  &-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    width: 25px;
    height: 25px;
  }

  /* Hide the browser's default checkbox */
  &-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &-board {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* Create a custom checkbox */
  &-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 9px;
    border: 1.5px solid #b9b5c2;
  }

  /* On mouse-over, add a grey background color */
  &-container:hover input ~ &-checkmark {
    border: 0px;
    background: #373737;
  }

  /* When the checkbox is checked, add a white background */
  &-container input:checked ~ &-checkmark {
    border: 0px;
    background: #f4f4f4;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  &-container input:checked ~ &-checkmark:after {
    display: block;
  }

  &-container input:hover ~ &-checkmark:after {
    display: block;
  }

  &-container :not(input:checked) ~ &-checkmark:after {
    border: 2px solid #ffffff;
    border-width: 0 2px 2px 0;
  }

  /* Style the checkmark/indicator */
  &-container &-checkmark:after {
    left: 9px;
    top: 2px;
    width: 5px;
    height: 15px;
    border: 2px solid #b9b5c2;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &-text {
    padding-top: 4px;
  }
}
