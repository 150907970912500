@import 'styles/variables';
@import 'styles/globals';

.formEdit {
  @include text-secondary-tab;
  background-color: transparent;
  outline: none;
  width: calc(100% - 30px);
  border: 0px;

  &-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #373737;
    border-width: 0 0 1px 0;
    align-items: center;
  }

  &-end-text {
    @include text-small-caps;
  }
}
