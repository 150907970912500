@import 'styles/variables';

.primaryButton {
  width: fit-content;
  margin-top: 24px;
  padding-right: 8px;

  &-default {
    font-family: $primary-font-family;
    font-size: 27px;
    border: none;
    background-color: transparent;
    color: $color-primary;
    text-align: left;
  }

  &:before {
    content: '→ ';
  }

  &:hover,
  &:focus {
    // color: $color-secondary;
    font-style: italic;
    transition: 0.3s;

    text-decoration: underline;
  }
}
