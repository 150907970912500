@import 'styles/globals';

.lot-display {
  &-container {
    max-width: 450px;
    width: 100%;
    cursor: pointer;
  }

  &-preview-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 450px;
    height: 460px;
    border-radius: 9px;
    overflow: hidden;

    @include md {
      width: 355px;
      height: 355px;
    }
  }

  &-image-container {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  &-image {
    width: 100%;
    height: auto;
  }

  &-bid-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
