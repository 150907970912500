@import 'styles/variables';
@import 'styles/globals';

$notification-max-width: 460px;
$notification-text-min-width: 340px;

.popup {
  &-main-container {
    user-select: none;
    display: flex;
    flex-direction: row;
    vertical-align: top;
    justify-content: space-between;
    padding: 13px;

    width: 100%;

    color: $color-primary;
    border-radius: 9px;
  }

  &-close {
    font-size: 19px;
    line-height: 20px;
    letter-spacing: -0.27px;
  }

  &-max-width {
    max-width: $notification-max-width;
  }
}
