@import 'styles/globals';

.empty {
  &-block {
    position: relative;
    height: 450px;
    &-text {
      margin-left: 20px;
      margin-top: 20px;
    }
  }
  &-auction-link {
    @include md {
      text-align: center;
      position: absolute;
      top: 20%;
      left: 20px;
    }
  }
}
