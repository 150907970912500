@import 'styles/globals';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  font-family: 'JJannon Italic';
  background-color: $color-soft-green;
  z-index: 3;

  &:hover {
    background-color: #eee;
  }

  &-container {
    position: absolute;
  }
}
