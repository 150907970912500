@import 'styles/globals';

// X Axis Animation
@keyframes x {
  100% {
    transform: translateX(calc(100vw - 1028px));
  }
}

@keyframes x-sm {
  100% {
    transform: translateX(calc(50vw - 200px));
  }
}

// Y Axis animation
@keyframes y {
  100% {
    transform: translateY(calc(100vh - 400px));
  }
}
@keyframes y-sm {
  100% {
    transform: translateY(calc(100vh - 500px));
  }
}

.x {
  position: absolute;
  background: rgba(255, 0, 0, 0.2);
  top: 200px;
  left: 200px;
  width: calc(100vw - 400px);
  height: calc(100vh - 400px);
  animation: x 8s linear infinite alternate;
  z-index: 1;
  @include xl {
    animation: x 12s linear infinite alternate;
  }
  @include md {
    animation: x-sm 8s linear infinite alternate;
    left: calc(100vh - 400px);
  }
  @include sm {
    animation: x-sm 3s linear infinite alternate;
    right: 0;
  }
}

.small-screen-button {
  position: relative;
  height: 30vh;
  width: 100%;
  margin-top: -50px;
}

.bigButton {
  position: absolute;
  top: -100px;
  right: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  font-family: 'JJannon Italic';
  background-color: $color-soft-green;
  animation: y 5s linear infinite alternate;
  transition: background-color 0.2s ease-in-out;
  @include xl {
    animation: y 8s linear infinite alternate;
  }
  @include md {
    animation: y-sm 7s linear infinite alternate;
  }
  @include sm {
    animation: y-sm 7s linear infinite alternate;
    right: 0;
    width: 120px;
    height: 120px;
  }
  &:hover {
    background-color: #eee;
  }
  z-index: 3;
}

.container {
  display: flex;
  max-width: $screen-lg;
  width: 100%;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
  z-index: 0;
  // border: 1px solid;
}

.heroText {
  max-width: $screen-xs;
  // min-height: 72vh;
  z-index: 3;
  > h1 {
    margin-bottom: 48px;
  }

  > h1:nth-child(3) {
    margin-bottom: 62px;
  }
}

.content {
  margin: 16px 0;
  z-index: 3;
  > p {
    // z-index: 3;
    line-height: 1.2;
  }
}
.artistList {
}
.image {
  max-width: 1031px;
  width: 80%;
  height: auto;
  margin: 40px 0;

  @include md {
    width: 100%;
    margin: 16px 0;
  }

  &-logo {
    max-height: 60px;
    margin: 24px 0;
    width: auto;

    @include md {
      max-height: 40px;
      width: auto;
    }
  }
}

.logoRow {
  max-width: $screen-xs;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
