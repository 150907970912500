@import '@styles/variables';

.content-container {
  width: 100%;
  max-width: $screen-xl;
  padding: 0.5em 1em;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word;

  @include md {
    padding-left: 10px;
    padding-right: 10px;
  }
}
