@import '@styles/globals';

.countdown {
  @include text-small;
  margin-top: 22px;
  width: 100%;
  height: 35px;
  max-width: 460px;
  margin-bottom: 121px;

  @include md {
    margin-bottom: 18px;
    margin-top: -5px;
  }

  &-container {
    max-width: 1054px;
    width: 100%;
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include md {
      padding-left: 0px;
    }
  }
}

.description {
  width: 100%;
  max-width: 1032px;
  margin: 40px 0px 50px 0px;

  @include md {
    margin: 20px 0px 40px 0px;
  }
}

.section-label {
  width: fit-content;
  margin-bottom: 30px;

  @include md {
    margin-bottom: 10px;
  }
}

.bid-items-section {
  margin-bottom: 86px;

  @include md {
    margin-bottom: 80px;
  }

  .display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    column-gap: 15px;
    row-gap: 50px;
    grid-template-columns: 8ch auto;

    &-item {
      max-width: 450px;

      @include md {
        max-width: 355px;
      }
    }

    &-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    @include md {
      //justify-content: center;
    }
  }
}

.artists {
  display: flex;
  flex-direction: row;
  margin-bottom: 206px;

  @include md {
    margin-bottom: 142px;
  }

  &-list-container {
    @include text-big-list;

    flex: 1;

    ul {
      list-style-type: none;
      padding: 0px;
    }
  }

  &-list-title {
    color: $color-secondary;
  }

  &-link {
    @include text-normal;
    margin-top: 36px;

    @include md {
      display: flex;
      justify-content: center;
      margin-top: 100px;
    }
  }

  &-img-container {
    flex: 1;
  }
}

.lots {
  &-container {
    width: 100%;
    padding: 50px 0px 30px 0px;
    background-color: $color-lightest-gray;
  }

  &-italic {
    font-style: italic;
  }

  &-info {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &-container {
      display: flex;
      flex-direction: column;
      width: 450px;
      padding: 13px;
      background: #ffffff;
      border-radius: 9px;

      @include md {
        width: 264px;
      }
    }
  }

  &-link {
    @include text-normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 114px;

    @include md {
      margin-top: 80px;
    }
  }
}

.artwork {
  &-slider {
    width: 100%;
    height: 600px;
    background-color: $color-lightest-gray;
    margin-bottom: 15px;

    @include md {
      height: 543px;
    }
  }
}
