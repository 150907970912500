@import 'styles/variables';
@import 'styles/globals';

.footer {
  &-container {
    color: black;
    background-color: $color-light-gray;
    padding: 25px 0px;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
  }
}
