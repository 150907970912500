@import 'styles/globals';

.artists {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0px 130px 0px;

    @include md {
      margin: 10px 0px 100px 0px;
    }
  }

  &-image {
    max-width: 681px;
    max-height: 681px;
    width: 100%;
    height: 100%;

    > * {
      width: 100%;
      height: 100%;
    }

    @include md {
      width: 0px;
      height: 0px;
    }
  }
}
