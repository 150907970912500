@import 'styles/variables';

.app {
  &-container {
    background-color: #fff;
    color: black;
  }

  &-popup {
    display: flex;
    flex-direction: column;
    font-size: 19px;
    line-height: 20px;
    letter-spacing: -0.27px;
  }

  &-button {
    background-color: $color-primary;
    color: white;
  }
}
