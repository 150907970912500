@import 'styles/globals';

.artwork-slider {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 60px;
    @include text-big;
  }

  &-motion-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-arrows {
    margin-top: 30px;
    cursor: pointer;
    user-select: none;
  }

  &-details {
    width: 50%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 9px;
    padding: 13px 0px 13px 13px;
    @include text-normal;
  }

  &-item-block {
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &-image-block {
    min-width: 300px;
    min-height: 300px;
    max-width: 450px;
    max-height: 450px;
    height: auto;
    width: 42%;
    border-radius: 9px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &-image {
    width: 100%;
    height: auto;
  }
}
