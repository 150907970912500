@import 'styles/variables';
@import 'styles/globals';

.dropdown {
  &-container {
    width: 300px;
  }

  &-text {
    color: $color-secondary;
  }

  &-option {
    cursor: pointer;
  }

  &-display-box {
    background: #ffffff;
    border-radius: 9px;
    width: 300px;
    padding: 13px 0px;
    text-align: right;
  }

  &-list {
    position: fixed;
    bottom: 0px;
    left: 0px;
    border-radius: 9px;
    background-color: #fff;
    opacity: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 6;
    padding: 20px 0px 0px 10px;
    font-size: 20px;
    line-height: 35px;

    &-container {
      position: fixed;
      bottom: 0px;
      left: 0px;
      background: rgba($color: $color-light-gray, $alpha: 0.9);
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }

  &-cancel {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 40px;
  }
}
